import { Button } from '@fluentui/react-button';
import { Field, Input, Textarea, makeStyles, tokens } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { store } from '../../../../redux/app/store';
import { FluentIconViewer } from '../../../icon-picker/FluentIconViewer';

const useClasses = makeStyles({
    root: {
        width: '600px',
    },
    actions: {
        paddingTop: '10%',
    },
    createButton: {
        marginTop: tokens.spacingVerticalXL,
        marginBottom: tokens.spacingVerticalXL,
        marginLeft: tokens.spacingHorizontalXL,
        marginRight: tokens.spacingHorizontalXL,
        width: 'fit-content',
    },
    textareaInput: {
        height: '100px',
    },
    error: {
        color: '#d13438',
    },
    pluginList: {
        margin: 0,
    },
    content: {
        "& > div:not(:first-child)": {
            marginTop: '.5rem',
        }
    },
});

interface IViewPersonaDialogProps {
    personaId: string;
}

export interface ViewPersonaDialogRef {
    openDialog: () => void;
}

export const ViewPersonaDialog = forwardRef<ViewPersonaDialogRef, IViewPersonaDialogProps>(({ personaId }, ref) => {
    const classes = useClasses();

    const [personaName, setPersonaName] = useState('');
    const [personaInstructions, setPersonaInstructions] = useState('');
    const [personaIconName, setPersonaIconName] = useState<string | undefined>('');
    const [personaIconBackgroundColor, setPersonaIconBackgroundColor] = useState('');
    const [personaPluginNames, setPersonaPluginNames] = useState<string[]>([]);
    const [open, setOpen] = useState(false);

    const [personaPlugins] = useState(store.getState().personas.personaPlugins);

    // Expose setOpen method to parent components
    useImperativeHandle(ref, () => ({
        openDialog: () => {
            // Load persona data from redux store
            setPersonaName(store.getState().personas.personas[personaId].name);
            setPersonaInstructions(store.getState().personas.personas[personaId].instructions);
            setPersonaIconName(store.getState().personas.personas[personaId].iconName);
            setPersonaIconBackgroundColor(store.getState().personas.personas[personaId].iconBackgroundColor);
            setPersonaPluginNames(store.getState().personas.personas[personaId].pluginNames);
            
            setOpen(true);
        }
    }));

    return (
        <Dialog
            modalType="alert"
            open={open}
            onOpenChange={(_event, data) => {
                setOpen(data.open);
            }}
        >
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle>View Persona:</DialogTitle>
                    <DialogContent className={classes.content}>
                        <Field label="Persona Name">
                            <Input
                                disabled={true}
                                required
                                type="text"
                                value={personaName}
                            />
                        </Field>
                        <Field label="Instructions">
                            <Textarea
                                disabled={true}
                                required
                                textarea={{ className: classes.textareaInput }}
                                resize='vertical'
                                value={personaInstructions}
                            />
                        </Field>
                        <Field label="Icon">
                            <FluentIconViewer iconName={personaIconName} iconBackgroundColor={personaIconBackgroundColor} />
                        </Field>
                        {personaPluginNames.length > 0 && (
                            <Field label="Enabled Plugins">
                                <ul className={classes.pluginList}>
                                    {personaPluginNames.map((pluginName, index) => {
                                        const pluginData = personaPlugins.find((plugin) => plugin.pluginName === pluginName);
                                        return pluginData && (
                                            <li key={index}>
                                                {`${pluginData.pluginDisplayName} - `}
                                                <span dangerouslySetInnerHTML={{ __html: pluginData.pluginDescriptionFull }} />
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Field>
                        )}
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="secondary">Close</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
});

ViewPersonaDialog.displayName = 'ViewPersonaDialog';