import { Button } from '@fluentui/react-button';
import { DialogOpenChangeData, makeStyles, Spinner } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { usePersonas } from '../../../../libs/hooks/usePersonas';
import { store } from '../../../../redux/app/store';

const useClasses = makeStyles({
    root: {
        width: '450px',
    },
    actions: {
        paddingTop: '10%',
    },
});

interface IDeletePersonaDialogProps {
    personaId: string;
}

export interface DeletePersonaDialogRef {
    openDialog: () => void;
}

export const DeletePersonaDialog = forwardRef<DeletePersonaDialogRef, IDeletePersonaDialogProps>(({ personaId }, ref) => {
    const classes = useClasses();
    const personas = usePersonas();

    const [personaName, setPersonaName] = useState('');
    const [chatsAffected, setChatsAffected] = useState('');
    const [open, setOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const onDeletePersona = () => {
        if (submitting) {
            return;
        }
        setSubmitting(true);
        void personas.deletePersona(personaId)
            .finally(() => { 
                setSubmitting(false);
            });
    };

    // Expose setOpen method to parent components
    useImperativeHandle(ref, () => ({
        openDialog: () => {
            // Load persona data from redux store
            setPersonaName(store.getState().personas.personas[personaId].name);
            void personas.getPersonaLinkedChatCount(personaId).then((count) => { 
                setChatsAffected(count.toString());
            });
            setOpen(true);
        }
    }));

    return (
        <Dialog
            modalType="alert"
            open={open}
            onOpenChange={(_ev: any, data: DialogOpenChangeData) => {
                setOpen(data.open);
            }}
        >
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle>Are you sure you want to delete persona: {personaName}?</DialogTitle>
                    <DialogContent>
                        This action will permanently delete the persona,
                        and any associated chats will no longer use this persona. 
                        <p>
                            <strong>
                                {chatsAffected !== '' ? chatsAffected : '...'} chat{chatsAffected != '1' ? 's' : ''} will be affected.
                            </strong>
                        </p>
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="secondary">Cancel</Button>
                        </DialogTrigger>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button
                                appearance="primary"
                                onClick={onDeletePersona}
                                icon={submitting ? <Spinner size="tiny" /> : undefined}
                                disabledFocusable={submitting}
                            >
                                Delete
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
});

DeletePersonaDialog.displayName = 'DeletePersonaDialog';