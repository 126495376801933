import { makeStyles, shorthands } from '@fluentui/react-components';
import { FC, useState } from 'react';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { RightHandPanel } from '../../redux/features/app/AppState';
import { ChatWindow } from '../chat/ChatWindow';
import { ChatList } from '../chat/chat-list/ChatList';
import { PersonaExploreWindow } from '../chat/persona/PersonaExploreWindow';

const useClasses = makeStyles({
    container: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        height: '100%',
    },
});

export const ChatView: FC = () => {
    const classes = useClasses();
    const selectedIdNotEmpty = useAppSelector((state: RootState) => state.conversations.selectedId !== '');
    const activeRightHandPanel = useAppSelector((state: RootState) => state.app.activeRightHandPanel);
    const [isEditingChatName, setIsEditingChatName] = useState<boolean>(false);

    return (
        <div className={classes.container}>
            <ChatList setIsEdit={setIsEditingChatName} />
            {activeRightHandPanel === RightHandPanel.Chat && selectedIdNotEmpty && (
                <ChatWindow isEditing={isEditingChatName} setIsEditing={setIsEditingChatName} />
            )}
            {activeRightHandPanel === RightHandPanel.Persona && <PersonaExploreWindow />}
        </div>
    );
};
