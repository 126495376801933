import { Card, makeStyles } from '@fluentui/react-components';

const useClasses = makeStyles({
    exampleText: {
        width: '200px',
        height: '100px',
        marginTop: '.5em',
        marginBottom: '.5em',
        marginLeft: '.5em',
        marginRight: '.5em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
    },
    exampleTextSmall: {
        width: '200px',
        height: '60px',
        marginTop: '.5em',
        marginBottom: '.5em',
        marginLeft: '.5em',
        marginRight: '.5em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
    },
});

interface Props {
    text: string;
    value: string;
    onClick: (value: string) => void;
    isSmallWindow: boolean;
}

export const Example = ({ text, value, onClick, isSmallWindow }: Props) => {
    const classes = useClasses();

    return (
        <Card
            onSelectionChange={() => {
                onClick(value);
            }}
        >
            <p className={`${!isSmallWindow ? classes.exampleText : classes.exampleTextSmall}`}>{text}</p>
        </Card>
    );
};
