import { useMsal } from '@azure/msal-react';
import { useAppDispatch } from '../../redux/app/hooks';
import { FeatureKeys, Features } from '../../redux/features/app/AppState';
import { addAlert, changeMultipleFeatureFlags, disableFeatureFlag, enableFeatureFlag, toggleFeatureFlag } from '../../redux/features/app/appSlice';
import { AuthHelper } from '../auth/AuthHelper';

import { AlertType } from '../models/AlertType';
import { IUserSetting } from '../models/UserSetting';
import UserSettingsService from '../services/UserSettingsService';

export const useUserSettings = () => {
    const { instance, inProgress } = useMsal();
    const dispatch = useAppDispatch();

    const userSettingsService: UserSettingsService = new UserSettingsService(
        process.env.REACT_APP_BACKEND_URI as string,
    );

    const getUserSettings = async () => {
        /* eslint-disable 
            @typescript-eslint/no-unsafe-assignment,
            @typescript-eslint/no-unsafe-member-access,
            @typescript-eslint/no-unsafe-return
        */
        // Retrieve user settings from the data store
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);

        const result: IUserSetting[] = await userSettingsService.getUserSettings(accessToken);

        let userSettingsList: typeof Features = Features;

        result.forEach((newSetting) => {
            const featureKey = newSetting.featureKeyId as FeatureKeys;

            const existingSetting = userSettingsList[featureKey];

            userSettingsList = {
                ...userSettingsList,
                [newSetting.featureKeyId]: {
                    enabled: newSetting.isEnabled,
                    label: existingSetting.label,
                },
            };
        });

        return userSettingsList;
    };

    const toggleUserSetting = async (featureKey: FeatureKeys) => {
        console.log(`toggleUserSetting ${featureKey}`);
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);

        await userSettingsService
            .saveUserSettings(featureKey, accessToken)
            .then(() => {
                dispatch(toggleFeatureFlag(featureKey));
            })
            .catch(() => {
                const errorDetails = `Failed to save user setting.`;
                dispatch(
                    addAlert({
                        message: `${errorDetails}`,
                        type: AlertType.Error,
                        onRetry: () => void toggleUserSetting(featureKey),
                    }),
                );
            });
    };

    const disableUserSetting = async (featureKey: FeatureKeys) => {
        console.log(`disableUserSetting ${featureKey}`);
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);

        await userSettingsService
            .saveUserSettingsDisable(featureKey, accessToken)
            .then(() => {
                dispatch(disableFeatureFlag(featureKey));
            })
            .catch(() => {
                const errorDetails = `Failed to save user setting.`;
                dispatch(
                    addAlert({
                        message: `${errorDetails}`,
                        type: AlertType.Error,
                        onRetry: () => void disableUserSetting(featureKey),
                    }),
                );
            });
    };

    const enableUserSetting = async (featureKey: FeatureKeys) => {
        console.log(`enableUserSetting ${featureKey}`);
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);

        await userSettingsService
            .saveUserSettingsEnable(featureKey, accessToken)
            .then(() => {
                dispatch(enableFeatureFlag(featureKey));
            })
            .catch(() => {
                const errorDetails = `Failed to save user setting.`;
                dispatch(
                    addAlert({
                        message: `${errorDetails}`,
                        type: AlertType.Error,
                        onRetry: () => void enableUserSetting(featureKey),
                    }),
                );
            });
    };

    const changeMultipleUserSettings = async (newFeatureFlags: Record<FeatureKeys, boolean>) => {
        console.log('changeMultipleUserSettings');
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);

        await userSettingsService
            .saveUserSettingsMultiple(newFeatureFlags, accessToken)
            .then(() => {
                dispatch(changeMultipleFeatureFlags(newFeatureFlags));
            })
            .catch(() => {
                const errorDetails = `Failed to save user setting.`;
                dispatch(
                    addAlert({
                        message: `${errorDetails}`,
                        type: AlertType.Error,
                        onRetry: () => void changeMultipleUserSettings(newFeatureFlags),
                    }),
                );
            });
    }

    return {
        getUserSettings,
        toggleUserSetting,
        disableUserSetting,
        enableUserSetting,
        changeMultipleUserSettings,
    };
};
