import { FC } from 'react';

import { Button, Tooltip } from '@fluentui/react-components';
import { CiFilter } from 'react-icons/ci';

interface CustomFilter {
    onFilterClick: () => void;
    className?: string;
}

export const CustomFilter: FC<CustomFilter> = ({ onFilterClick, className }) => {
    return (
        <div className={className}>
            <Tooltip content="Filter conversations by name" relationship="label">
                <Button icon={<CiFilter />} appearance="transparent" onClick={onFilterClick} />
            </Tooltip>
        </div>
    );
};
