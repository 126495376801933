export enum Temp {
    Adventurous = 0.9,
    Creative = 0.7,
    Balanced = 0.5,
    Thoughtful = 0.3,
    Precise = 0.1
};

export function temperatureFromNumberText(temp: string): Temp {
    // Round to 1 decimal place and convert to temperature enum
    const num = Math.round(parseFloat(temp) * 1e1) / 1e1;
    switch (num) {
        case 0.9:
            return Temp.Adventurous;
        case 0.7:
            return Temp.Creative;
        case 0.3:
            return Temp.Thoughtful;
        case 0.1:
            return Temp.Precise;
        default:
            return Temp.Balanced;
    }
}