import { FC } from 'react';

import { Button, Tooltip } from '@fluentui/react-components';
import { ArrowUploadRegular } from '@fluentui/react-icons';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { FeatureKeys } from '../../../redux/features/app/AppState';

interface UploadChatProps {
    onFileUpload: () => void;
}

export const UploadChat: FC<UploadChatProps> = ({ onFileUpload }) => {
    const features = useAppSelector((state: RootState) => state.app.features);

    return (
        <div>
            <Tooltip content="Upload conversation" relationship="label">
                <Button
                    disabled={!features[FeatureKeys.BotAsDocs].enabled}
                    icon={<ArrowUploadRegular />}
                    onClick={onFileUpload}
                    appearance="transparent"
                />
            </Tooltip>
        </div>
    );
};
