import { FC } from 'react';

import { Button, Tooltip } from '@fluentui/react-components';
import { NavigationRegular } from '@fluentui/react-icons';

interface SideBarCollapser {
    onSideBarCollapseClick: () => void;
}

export const SideBarCollapser: FC<SideBarCollapser> = ({ onSideBarCollapseClick }) => {
    return (
        <div>
            <Tooltip content="Hide/expand conversation panel" relationship="label">
                <Button icon={<NavigationRegular />} appearance="transparent" onClick={onSideBarCollapseClick} />
            </Tooltip>
        </div>
    );
};
