import { Button, ColorSwatch, DrawerBody, DrawerFooter, DrawerHeader, DrawerHeaderTitle, Label, makeStyles, mergeClasses, OverlayDrawer, PopoverProps, renderSwatchPickerGrid, SearchBox, SelectTabData, SelectTabEvent, shorthands, SwatchPicker, SwatchPickerOnSelectEventHandler, SwatchProps, Tab, TabList, TabValue, tokens, useId } from '@fluentui/react-components';
import * as iconModule from '@fluentui/react-icons';
import { ColorRegular, Dismiss24Regular, EditRegular, PersonRegular, SearchRegular } from '@fluentui/react-icons';
import { debounce } from 'lodash';
import { cloneElement, FC, useMemo, useState } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { defaultPersonaBackgroundColors, getRandomDefaultPersonaBackgroundColor } from '../../libs/utils/PersonaIconColorUtils';
import { getIconByName } from '../../libs/utils/PersonaIconComponentUtils';
import { FluentIconViewer } from './FluentIconViewer';

const useClasses = makeStyles({
    listBody: {
        '&:hover': {
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: tokens.colorScrollbarOverlay,
                visibility: 'visible',
            },
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorSubtleBackground,
        },
    },
    drawerFooter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '10px 20px',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    iconList: {
        listStyleType: "none",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        padding: 0,
        margin: "0px",
    },
    iconItem: {
        display: "inline-block",
        padding: 0,
        margin: "0 2px 4px",
        listStyleType: "none",
        position: "relative",
        overflow: "hidden",
        border: "1px solid transparent",
    },
    iconItemSelected: {
        border: "1px dashed",
        ...shorthands.borderColor(tokens.colorBrandForeground1),
    },
    iconRadio: {
        position: "absolute",
        left: "-1000px",
        opacity: 0,
    },
    iconLabel: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        width: "78px",
        padding: "5px",
        height: "70px",
        borderRadius: "3px",
        wordBreak: "break-word",  // will cut off long icon names
        backgroundColor: tokens.colorNeutralBackground4,
        "&:after": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: "2px solid transparent",
            ...shorthands.borderColor(tokens.colorBrandForeground1),
            borderRadius: "3px",
            opacity: 0,
            willChange: "opacity, border-color",
            transition: "opacity ease-out 0.05s",
        },
        "&:hover": {
            "&:after": {
                opacity: 1,
            },
        },
    },
    iconGlyph: {
        fontSize: "24px",
        width: "24px",
        height: "24px",
        marginBottom: "5px",
        color: "inherit",
    },
    iconName: {
        maxWidth: "100%",
        textAlign: "center",
        fontSize: "12px",
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
    },
    iconSearchContainer: {
        display: "flex",
        justifyContent: "center",
    },
    iconSearch: {
        width: "70%",
    },
    drawer: {
        width: "440px",
    },
    editIcon: {
        cursor: "pointer",
        paddingLeft: "5px",
    },
    selectedFooterIconContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    selectedFooterIconText: {
        marginRight: "5px",
    },
    selectedFooterIcon: {
        height: "24px",
        width: "24px",
    },
    selectedFooterIconBlank: {
        color: tokens.colorNeutralStroke1
    },
    drawerHeaderTitle: {
        justifyContent: 'center',
    },
    tabs: {
        marginTop: '1rem',
    },
    drawerHeaderClose: {
        position: 'absolute',
        right: '0px',
        transform: 'translateX(-100%)',
    },
    colorSelection: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '1rem',
        padding: '1rem',
        textAlign: 'center',
    },
    colorSelectionCustom: {
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem',
        alignItems: 'center',
    },
    colorSelectionRender: {
        marginTop: '1rem',
    }
});

interface IFluentIconPickerProps {
    currentIconName?: string;
    currentIconBackgroundColor?: string;
    iconOnChange: (iconName: string, iconBackgroundColor: string) => void;
    focusOnClose?: () => void;
}

interface IconInformation {
    iconName?: string;
    iconComponent: JSX.Element | null;
}

export const FluentIconPicker: FC<IFluentIconPickerProps> = ({
    currentIconName,
    currentIconBackgroundColor,
    iconOnChange,
    focusOnClose
}) => {
    const classes = useClasses();
    const inputId = useId("input");
    const radioGroupName = useId("input");

    const [shownIconName, setShownIconName] = useState<string | undefined>(currentIconName);
    const [shownIconBackgroundColor, setShownIconBackgroundColor] =
        useState<string | undefined>(currentIconBackgroundColor);

    const [open, setOpen] = useState(false);
    const handleOpenChange: PopoverProps["onOpenChange"] = (_, data) => {
        setOpen(data.open || false);
        if (!data.open) {
            // Reset the icon selection
            setSelectedIcon({
                iconName: currentIconName,
                iconComponent: currentIconName ? getIconByName(currentIconName): null
            });

            // Reset the color selection
            if (currentIconBackgroundColor) {
                setSelectedColor(currentIconBackgroundColor);
            } else if (!selectedColor) {
                setSelectedColor(getRandomDefaultPersonaBackgroundColor());
            }

            if (focusOnClose) {
                focusOnClose();
            }

            // Reset the tab
            setSelectedTab("iconSelectionTab");
        }
    };

    const [selectedTab, setSelectedTab] = useState<TabValue>("iconSelectionTab");
    const onTabSelect = (_: SelectTabEvent, data: SelectTabData) => {
        setSelectedTab(data.value);
    };

    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue, setDebouncedSearchValue] = useState('');

    // All icons list
    const iconList = useMemo<Array<[string, string, JSX.Element | null]>>(() => {
        let icons = Object.keys(iconModule);

        // Select ones that end in "Regular" where the character right before isn't a digit
        icons = icons.filter(iconName => /[^0-9](Regular)$/.test(iconName));

        // Remove "Regular" from icon name and split on Capital letters
        const getDisplayIconName = (iconName: string) => {
            return iconName
                .replace(/(Regular)$/, '')
                .replace(/([A-Z])/g, ' $1')
                .trim();
        };

        const iconNamesAndDisplayNames: Array<[string, string, JSX.Element | null]> = icons.map(iconName => {
            return [iconName, getDisplayIconName(iconName), getIconByName(iconName)];
        });

        return iconNamesAndDisplayNames;
    }, []);

    const filteredIconList = useMemo(() => {
        if (!debouncedSearchValue) {
            return iconList;
        }
        return iconList.filter((iconName) => {
            return iconName[1].toLowerCase().includes(debouncedSearchValue.toLowerCase());
        });
    }, [debouncedSearchValue, iconList]);

    const [selectedIcon, setSelectedIcon] = useState<IconInformation>({
        iconName: currentIconName,
        iconComponent: currentIconName ? getIconByName(currentIconName): null
    });

    const filter = (filterInput: string) => { setDebouncedSearchValue(filterInput); };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const debounced = debounce(filter, 300);
    
    const [selectedColor, setSelectedColor] = useState<string>(() => {
        return currentIconBackgroundColor
            ? currentIconBackgroundColor
            : getRandomDefaultPersonaBackgroundColor();
    });

    const colorSwatchPickerSelectionChanged: SwatchPickerOnSelectEventHandler = (_, data) => {
        setSelectedColor(data.selectedSwatch);
    };

    const colorInputSelectionChanged = (value: string): void => {
        setSelectedColor(value);
    }

    return (
        <>
            <FluentIconViewer
                className={classes.editIcon}
                iconName={shownIconName}
                iconBackgroundColor={shownIconBackgroundColor}
                iconSize={40}
                badge={{ icon: <EditRegular /> }}
                onClick={() => { setOpen(true); }}
            />
            <OverlayDrawer
                open={open}
                onOpenChange={handleOpenChange}
                position='end'
                className={classes.drawer}
            >
                <DrawerHeader>
                    <DrawerHeaderTitle
                        className={classes.drawerHeaderTitle}
                        action={
                            <Button
                                className={classes.drawerHeaderClose}
                                appearance="subtle"
                                aria-label="Close"
                                icon={<Dismiss24Regular />}
                                onClick={() => { setOpen(false); }}
                            />
                        }
                    >
                        <TabList selectedValue={selectedTab} onTabSelect={onTabSelect} className={classes.tabs}>
                            <Tab value="iconSelectionTab" icon={<PersonRegular />}>Icon Selection</Tab>
                            <Tab value="colorSelectionTab" icon={<ColorRegular />}>Color Selection</Tab>
                        </TabList>
                    </DrawerHeaderTitle>
                    {selectedTab === "iconSelectionTab" && (
                        <div className={classes.iconSearchContainer}>
                            <SearchBox
                                id={inputId}
                                contentBefore={<SearchRegular />}
                                placeholder="Search icons"
                                value={searchValue}
                                onChange={(_, data) => {
                                    setSearchValue(data.value);
                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                                    debounced(data.value);
                                }}
                                className={classes.iconSearch}
                            />
                        </div>
                    )}
                </DrawerHeader>
                <DrawerBody>
                    {selectedTab === "iconSelectionTab" && (
                        <div className={classes.root}>
                            <AutoSizer>
                                {({ height, width }: { height: number, width: number }) => {
                                    // 6px scrollbar
                                    // 94px icon width = 88 width + 2 L margin + 2 R margin + 1 L border + 1 R border
                                    const itemsPerRow = Math.floor((width - 6) / 94);
                                    const rowCount = Math.ceil(filteredIconList.length / itemsPerRow);
                                    return (
                                        <List
                                            className={classes.listBody}
                                            width={width}
                                            height={height}
                                            rowCount={rowCount}
                                            // 86px height = 70px label height + 5 T label padding + 5 B label padding 
                                            // + 1 T li border + 1 B li border + 4 bottom li margin
                                            rowHeight={86}
                                            rowRenderer={
                                                ({ key, index, style }: { key: string, index: number, style: React.CSSProperties }) => {
                                                    const fromIndex = index * itemsPerRow;
                                                    const toIndex = Math.min(filteredIconList.length, fromIndex + itemsPerRow);
                                                    
                                                    return (
                                                        <ul className={classes.iconList} style={style} key={key}>
                                                            {filteredIconList.slice(fromIndex, toIndex).map(iconName => {
                                                                const IconComponent = iconName[2];
                                                                const radioId = `${iconName[0]}-radio`;
                                                                const isSelected = selectedIcon.iconName === iconName[0];
                                                                return (
                                                                    <li
                                                                        className={isSelected
                                                                            ? mergeClasses(classes.iconItem, classes.iconItemSelected)
                                                                            : classes.iconItem}
                                                                        key={iconName[0]}
                                                                        title={iconName[1]}
                                                                    >
                                                                        <input
                                                                            type='radio'
                                                                            id={radioId}
                                                                            name={radioGroupName}
                                                                            checked={isSelected}
                                                                            className={classes.iconRadio}
                                                                            onChange={() => { 
                                                                                setSelectedIcon({
                                                                                    iconName: iconName[0],
                                                                                    iconComponent: IconComponent
                                                                                });
                                                                            }}
                                                                        />
                                                                        <Label htmlFor={radioId} className={classes.iconLabel}>
                                                                            <span className={classes.iconGlyph}>
                                                                                {IconComponent}
                                                                            </span>
                                                                            <span className={classes.iconName}>
                                                                                {iconName[1]}
                                                                            </span>
                                                                        </Label>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    );
                                                }
                                            }
                                        />
                                    )
                                }}
                            </AutoSizer>
                        </div>
                    )}
                    {selectedTab === "colorSelectionTab" && (
                        <div className={classes.colorSelection}>
                            <Label>Default colors:</Label>
                            <SwatchPicker
                                layout='grid'
                                size='large'
                                selectedValue={selectedColor}
                                onSelectionChange={colorSwatchPickerSelectionChanged}
                            >
                                {renderSwatchPickerGrid({
                                    items: defaultPersonaBackgroundColors
                                        .map((item) => ({ color: item, value: item })),
                                    columnCount: 6,
                                    renderSwatch: (item: SwatchProps) => (
                                        <ColorSwatch
                                            key={item.value}
                                            color={item.value}
                                            value={item.value}
                                        />
                                    )
                                })}
                            </SwatchPicker>
                            <div className={classes.colorSelectionCustom}>
                                <Label>Custom Color Picker:</Label>
                                <input
                                    type="color"
                                    value={selectedColor}
                                    onChange={e => { colorInputSelectionChanged(e.target.value); }}
                                />
                            </div>
                            <FluentIconViewer
                                className={classes.colorSelectionRender}
                                iconSize={40}
                                iconName={selectedIcon.iconName}
                                iconBackgroundColor={selectedColor}
                            />
                        </div>
                    )}
                </DrawerBody>
                <DrawerFooter className={classes.drawerFooter}>
                    <Button
                        aria-label="Clear"
                        onClick={() => {
                            // Clear selected icon
                            setSelectedIcon({
                                iconName: undefined,
                                iconComponent: null
                            });

                            // Clear initial icon
                            currentIconName = "";

                            // Reset initial color to a random default color if it isn't already a default color
                            if (!defaultPersonaBackgroundColors.includes(currentIconBackgroundColor ?? '')) {
                                currentIconBackgroundColor = getRandomDefaultPersonaBackgroundColor();
                            }

                            // Clear selected color
                            setSelectedColor(currentIconBackgroundColor
                                ? currentIconBackgroundColor
                                : getRandomDefaultPersonaBackgroundColor());

                            // Update the shown icon
                            setShownIconName(currentIconName);
                            setShownIconBackgroundColor(currentIconBackgroundColor);

                            // Callback to parent to clear icon name setting
                            iconOnChange('', '');
                            
                            setOpen(false);
                            if (focusOnClose) {
                                focusOnClose();
                            }

                            // Reset the tab
                            setSelectedTab("iconSelectionTab");
                        }}
                    >
                        Clear
                    </Button>
                    {selectedTab === "iconSelectionTab" && (
                        <div className={classes.selectedFooterIconContainer}>
                            <span
                                className={selectedIcon.iconComponent
                                    ? classes.selectedFooterIconText
                                    : mergeClasses(classes.selectedFooterIconText, classes.selectedFooterIconBlank)
                                }
                            >
                                Selected:
                            </span>
                            {selectedIcon.iconComponent
                                ? cloneElement(selectedIcon.iconComponent, { className: classes.selectedFooterIcon })
                                : <div className={classes.selectedFooterIcon}></div>
                            }
                        </div>
                    )}
                    {selectedTab === "iconSelectionTab" && (
                        <Button
                            aria-label="Next tab"
                            appearance='primary'
                            onClick={() => {
                                setSelectedTab("colorSelectionTab");
                            }}
                        >
                            Next
                        </Button>
                    )}
                    {selectedTab === "colorSelectionTab" && (
                        <Button
                            aria-label="Save"
                            appearance='primary'
                            onClick={() => {
                                // Set icon name stored in component for reopening
                                currentIconName = selectedIcon.iconName;

                                // Set icon background color for reopening
                                // selectedColor should be not empty since it was set on open
                                if (selectedColor) {
                                    currentIconBackgroundColor = selectedColor;
                                } else {
                                    currentIconBackgroundColor = getRandomDefaultPersonaBackgroundColor();
                                }
                                
                                // Update the shown icon
                                setShownIconName(currentIconName);
                                setShownIconBackgroundColor(currentIconBackgroundColor);
                                
                                // Callback to parent to set icon name
                                iconOnChange(currentIconName ?? '', currentIconBackgroundColor);
                                
                                setOpen(false);
                                if (focusOnClose) {
                                    focusOnClose();
                                }

                                // Reset the tab
                                setSelectedTab("iconSelectionTab");
                            }}
                        >
                            Save
                        </Button>
                    )}
                </DrawerFooter>
            </OverlayDrawer>
        </>
    );
};
