import {
    Button,
    Link,
    Popover,
    PopoverSurface,
    PopoverTrigger,
    Radio,
    RadioGroup,
    Text,
    makeStyles
} from "@fluentui/react-components";
import * as React from "react";
import { SearchTypeDict } from "./SearchType";

const useStyles = makeStyles({
    contentHeader: {
        marginTop: "0",
    },
    searchTypeTextLargeView: {
        display: "inline",
        '@media (max-width: 1140px)': {
            display: "none"
        }
    },
    searchTypeTextSmallView: {
        display: "none",
        '@media (max-width: 1140px)': {
            display: "inline"
        }
    }
});

interface SearchTypeProps {
    searchTypeValue: string;
    setSearchTypeValue: React.Dispatch<React.SetStateAction<string>>;
}

export const SearchTypeControl = ({ searchTypeValue, setSearchTypeValue }: SearchTypeProps) => {
    const styles = useStyles();
  
    return (
        <div style={{ margin: "5px" }}>
            <Popover withArrow={true}>
                <PopoverTrigger disableButtonEnhancement>
                    <Button>
                        <span className={styles.searchTypeTextLargeView}>{`Search type: ${SearchTypeDict[searchTypeValue]}`}</span>
                        <span className={styles.searchTypeTextSmallView}>{SearchTypeDict[searchTypeValue]}</span>
                    </Button>
                </PopoverTrigger>

                <PopoverSurface>
                    <div>
                        <h4 className={styles.contentHeader}>Choose a search type:</h4>
                        <RadioGroup
                            value={searchTypeValue}
                            onChange={(_, data) => {
                                setSearchTypeValue(data.value)
                            }}
                        >
                            <Radio value={"ChatSearch"}
                                label={
                                    <>
                                        {SearchTypeDict.ChatSearch}
                                        <br />
                                        <Text size={200}>
                                            Eastman Copilot Chat
                                        </Text>
                                    </>
                                } />
                            <Radio value={"DocumentSearch"}
                                label={
                                    <>
                                        Chat with Copilot Documents
                                        <br />
                                        <Text size={200}>
                                            Chat with documents in the <Link target="_blank" href={process.env.REACT_APP_COPILOT_DOC_REPO_LINK}>Copilot Repository</Link>
                                        </Text>
                                        </>
                                } />
                        </RadioGroup>
                    </div>
                </PopoverSurface>
            </Popover>
        </div>
    );
};
