import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Link,
    makeStyles,
} from '@fluentui/react-components';
import { useState } from 'react';

const useClasses = makeStyles({
    primary: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    dialogBody: {
        maxHeight: '70vh',
    },
});

export const NewFeatureDialog: React.FC = () => {
    const classes = useClasses();
    const [isOpen, setIsOpen] = useState<boolean>(true);
    return (
        <Dialog open={isOpen}>
            <DialogSurface>
                <DialogBody className={classes.dialogBody}>
                    <DialogTitle>What&apos;s New</DialogTitle>
                    <DialogContent>
                        <hr />
                        <p className={classes.primary}>New features have been added to Eastman Copilot!</p>
                        <p>
                            <b>Image Generation.</b> Eastman Copilot&apos;s new image generation feature leverages the
                            DALL-E model to create high-quality, custom images based on your input. Just describe the
                            details such as style, color palette, and content, and Copilot will generate a tailored
                            visual for you. To use this feature, simply ask Copilot to generate an image followed by
                            your description - Copilot can detect whether you are asking for an image or not and respond
                            accordingly.{' '}
                            <i>
                                Please note that the quota for this feature is currently limited, so you might need to
                                try again if the service is busy.
                            </i>
                        </p>
                        <hr />
                        <p>
                            The Eastman Copilot V2 Quick Reference Guide can be accessed{' '}
                            <Link href={process.env.REACT_APP_COPILOT_QRG_LINK} target="_blank">
                                here
                            </Link>{' '}
                            for more detailed information about Eastman Copilot&apos;s features.
                        </p>
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button
                                appearance="secondary"
                                onClick={() => {
                                    setIsOpen(false);
                                }}
                            >
                                Close
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
