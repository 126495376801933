import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import App from './App';
import { Constants } from './Constants';
import './index.css';
import { AuthConfig, AuthHelper } from './libs/auth/AuthHelper';
import { store } from './redux/app/store';

import React from 'react';
import { BackendServiceUrl } from './libs/services/BaseService';
import { setAuthConfig } from './redux/features/app/appSlice';

import { ProviderState, Providers, SimpleProvider } from '@microsoft/mgt-element';

if (!localStorage.getItem('debug')) {
    localStorage.setItem('debug', `${Constants.debug.root}:*`);
}

// Clear local storage of authentication related to Copilot V1 app as it causes problems with V2
// Remove all keys that contain the V1 app's client id in the key or value
const searchKey = process.env.REACT_APP_COPILOT_V1_CLIENT_ID; // Client id of old frontend app
if (searchKey) {
    const keys = Object.keys(localStorage);
    keys.forEach((key) => {
        if (key.includes(searchKey)) {
            localStorage.removeItem(key);
        } else {
            const value = localStorage.getItem(key);
            if (value && value.includes(searchKey)) {
                localStorage.removeItem(key);
            }
        }
    });
}

//Used to connect authentication to MS Graph microsoft/mgt-react to be able to use those components
Providers.globalProvider = new SimpleProvider(async (scopes: string[]) => {
    // return a promise with accessToken
    try {
        const request = { scopes: scopes };
        const accessTokenResponse = await msalInstance?.acquireTokenSilent(request);
        if (accessTokenResponse == null) {
            return '';
        } else {
            return accessTokenResponse.accessToken;
        }
    } catch {
        return '';
    }
});
// set state to signal to all components to start calling graph
Providers.globalProvider.setState(ProviderState.SignedIn);

let container: HTMLElement | null = null;
let root: ReactDOM.Root | undefined = undefined;
let msalInstance: PublicClientApplication | undefined;

document.addEventListener('DOMContentLoaded', () => {
    if (!container) {
        container = document.getElementById('root');
        if (!container) {
            throw new Error('Could not find root element');
        }
        root = ReactDOM.createRoot(container);

        renderApp();
    }
});

export function renderApp() {
    fetch(new URL('authConfig', BackendServiceUrl))
        .then((response) => (response.ok
            ? (response.json() as Promise<AuthConfig>)
            : Promise.reject(new Error('authConfig fetch failed'))))
        .then((authConfig) => {
            store.dispatch(setAuthConfig(authConfig));

            if (AuthHelper.isAuthAAD()) {
                if (!msalInstance) {
                    msalInstance = new PublicClientApplication(AuthHelper.getMsalConfig(authConfig));
                    void msalInstance.handleRedirectPromise().then((response) => {
                        if (response) {
                            msalInstance?.setActiveAccount(response.account);
                        }
                    });
                }

                // render with the MsalProvider if AAD is enabled
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                root!.render(
                    <React.StrictMode>
                        <ReduxProvider store={store}>
                            <MsalProvider instance={msalInstance}>
                                <App />
                            </MsalProvider>
                        </ReduxProvider>
                    </React.StrictMode>,
                );
            }
        })
        .catch(() => {
            store.dispatch(setAuthConfig(undefined));
        });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    root!.render(
        <React.StrictMode>
            <ReduxProvider store={store}>
                <App />
            </ReduxProvider>
        </React.StrictMode>,
    );
}
