import { Button, MessageBar, MessageBarActions, MessageBarBody, makeStyles } from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';
import React, { useState } from 'react';
import { Breakpoints } from '../../styles';

const useClasses = makeStyles({
    expireNotice: {
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        ...Breakpoints.large({
            display: 'none',
        }),
    },
});

export const ExpireNoticeBanner: React.FC = () => {
    const classes = useClasses();
    const [isBannerVisible, setIsBannerVisible] = useState(true);
    const handleDismiss = () => {
        setIsBannerVisible(false);
    };
    return (
        <>
            {isBannerVisible && (
                <MessageBar shape="square" className={classes.expireNotice}>
                    <MessageBarBody>
                        Messages will only be kept for 90 days. To save your chats locally for access any time, press
                        &lsquo;Download Chat&rsquo; in the top left of the chat window.
                    </MessageBarBody>
                    <MessageBarActions
                        containerAction={
                            <Button appearance="transparent" icon={<DismissRegular />} onClick={handleDismiss} />
                        }
                    ></MessageBarActions>
                </MessageBar>
            )}
        </>
    );
};
