import {
    Button,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    Divider,
    Label,
    makeStyles,
    tokens,
} from '@fluentui/react-components';
import { MgtPeoplePicker, PeoplePicker } from '@microsoft/mgt-react';
import React from 'react';
import { useChat } from '../../../libs/hooks';
import { store } from '../../../redux/app/store';
import { ActiveUserInfo } from '../../../redux/features/app/AppState';

const useStyles = makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalMNudge,
    },
    divider: {
        marginTop: tokens.spacingVerticalXL,
        marginBottom: tokens.spacingVerticalXL,
    },
});

interface InvitationAddDialogProps {
    onCloseDialog: () => void;
    chatId: string;
}

export const InvitationAddDialog: React.FC<InvitationAddDialogProps> = ({ onCloseDialog, chatId }) => {
    const chat = useChat();
    const getActiveUserInfo = (): ActiveUserInfo | undefined => store.getState().app.activeUserInfo;
    const [errorOccurred, setErrorOccurred] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>('');

    const classes = useStyles();

    const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        setErrorOccurred(false);

        const pickerElement = document.querySelector('mgt-people-picker');
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        const people = (pickerElement as MgtPeoplePicker).selectedPeople;
        let userId = '';
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        const id = people.at(0)?.id;
        const tenantId = getActiveUserInfo()?.id.split('.')[1];
        const totalId = id + '.' + tenantId;
        if (totalId != '.') {
            userId = totalId;
        }

        void chat.addUserToChat(chatId, userId).then(({ success, message }) => {
            if (success) {
                onCloseDialog();
            } else {
                setErrorOccurred(true);
                setErrorMessage(message);
            }
        });
    };

    return (
        <div>
            <DialogSurface>
                <form onSubmit={handleSubmit}>
                    <DialogBody>
                        <DialogTitle>Add a Person</DialogTitle>
                        <DialogContent className={classes.content}>
                            <Label data-testid="enterUserInfoLabel" required htmlFor="user-info-input">
                                Please enter the name of the person you would like to add to this chat.
                            </Label>
                            <PeoplePicker userType="user" selectionMode="single"></PeoplePicker>
                        </DialogContent>
                        <DialogActions>
                            <Button appearance="secondary" onClick={onCloseDialog}>
                                Cancel
                            </Button>
                            <Button data-testid="addPersonButton" type="submit" appearance="primary">
                                Add
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </form>
                {errorOccurred && (
                    <div>
                        <Divider className={classes.divider} />
                        <Label size="large">{errorMessage}</Label>
                    </div>
                )}
            </DialogSurface>
        </div>
    );
};
