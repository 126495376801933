import { Button } from '@fluentui/react-components';
import { PersonFeedback24Regular } from '@fluentui/react-icons';

export const FeedbackForm: React.FC = () => {
    return (
        <Button
            data-testid="feedbackButton"
            style={{ color: 'white' }}
            appearance="transparent"
            icon={<PersonFeedback24Regular color="white" />}
            title="Feedback Form"
            onClick={() => {
                window.open(process.env.REACT_APP_FEEDBACK_FORM_LINK, '_blank');
            }}
        />
    );
};
