import { Button, makeStyles, shorthands, Text, tokens } from '@fluentui/react-components';
import { CommentAdd20Regular, MoreHorizontal20Regular } from '@fluentui/react-icons';
import { FC, useRef } from 'react';
import { useChat } from '../../../libs/hooks/useChat';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { setActiveRightHandPanel } from '../../../redux/features/app/appSlice';
import { FeatureKeys, RightHandPanel } from '../../../redux/features/app/AppState';
import { SharedStyles } from '../../../styles';
import { FluentIconViewer } from '../../icon-picker/FluentIconViewer';
import { PersonaOptionsMenu } from '../persona/PersonaOptionsMenu';

const useClasses = makeStyles({
    root: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        cursor: 'pointer',
        position: 'relative',
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground4Hover,
            paddingRight: '74px', // 10px + 32px wide (options button) + 32px wide (new chat button)
            '& button': {
                display: 'block !important',
            },
        },
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalXL),
    },
    body: {
        minWidth: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: tokens.spacingHorizontalXS,
        alignSelf: 'center',
    },
    header: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'relative',
    },
    title: {
        ...SharedStyles.overflowEllipsis,
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorNeutralForeground1,
    },
    previewText: {
        ...SharedStyles.overflowEllipsis,
        display: 'block',
        lineHeight: tokens.lineHeightBase100,
        color: tokens.colorNeutralForeground2,
    },
    selected: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    protectedIcon: {
        color: tokens.colorPaletteLightGreenBorder1,
        verticalAlign: 'text-bottom',
        marginLeft: tokens.spacingHorizontalXS,
    },
    optionsButton: {
        display: 'none',
        position: 'absolute',
        right: '42px', // 10px + 32px wide
        zIndex: 1,
        backgroundColor: tokens.colorNeutralBackground4Hover,
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground4Hover,
        },
    },
    newChatButton: {
        display: 'none',
        position: 'absolute',
        right: '10px',
        zIndex: 1,
        backgroundColor: tokens.colorNeutralBackground4Hover,
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground4Hover,
        },
    }
});

interface IPersonaListItemProps {
    id: string;
    isConversationPaneFill: boolean;
    closeConversationPanel: () => void;
}

export const PersonaListItem: FC<IPersonaListItemProps> = ({
    id,
    isConversationPaneFill,
    closeConversationPanel,
}) => {
    const classes = useClasses();
    const chat = useChat();
    const dispatch = useAppDispatch();
    const features = useAppSelector((state: RootState) => state.app.features);

    const name: string = useAppSelector((state: RootState) => state.personas.personas[id].name);
    const instructions: string = useAppSelector(
        (state: RootState) => state.personas.personas[id].instructions
    );
    const personaIconName = useAppSelector(
        (state: RootState) => state.personas.personas[id].iconName,
    );
    const personaIconBackgroundColor = useAppSelector(
        (state: RootState) => state.personas.personas[id].iconBackgroundColor,
    );

    const showPreview = !features[FeatureKeys.SimplifiedExperience].enabled && instructions;

    const rootRef = useRef<HTMLDivElement>(null);
    const onClick = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
        if (rootRef.current && !rootRef.current.contains(event.target as Node)) {
            return;
        }

        void chat.createChat(id);
        dispatch(setActiveRightHandPanel(RightHandPanel.Chat));

        if (isConversationPaneFill) {
            closeConversationPanel();
        }
    };

    return (
        <div
            className={classes.root}
            onClick={onClick}
            title={`Persona: ${name}`}
            aria-label={`Persona list item: ${name}`}
            ref={rootRef}
        >
            <FluentIconViewer iconName={personaIconName} iconBackgroundColor={personaIconBackgroundColor} />
            <div className={classes.body}>
                <div className={classes.header}>
                    <Text className={classes.title} title={name}>
                        {name}
                    </Text>
                </div>
                {showPreview && (
                    <>
                        {
                            <Text id={`persona-preview-${id}`} size={200} className={classes.previewText}>
                                {instructions}
                            </Text>
                        }
                    </>
                )}
            </div>
            <PersonaOptionsMenu
                personaId={id}
                TriggerComponent={
                    <Button
                        className={classes.optionsButton}
                        appearance="transparent"
                        icon={<MoreHorizontal20Regular />}
                        title="More options"
                        aria-label={`More options for persona: ${name}`}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    />
                    
                }
            />
            <Button
                className={classes.newChatButton}
                icon={<CommentAdd20Regular />}
                appearance="transparent"
                title="Create new chat with persona"
                aria-label={`Create new chat with persona: ${name}`}
                onClick={(event) => {
                    event.stopPropagation();
                    onClick(event);
                }}
            />
        </div>
    );
};
