import {
    Button,
    Popover,
    PopoverSurface,
    PopoverTrigger,
    Radio,
    RadioGroup,
    Text,
    makeStyles
} from "@fluentui/react-components";
import * as React from "react";
import { Temp } from "./Temp";

const useStyles = makeStyles({
    contentHeader: {
        marginTop: "0",
    },
    conversationStyleTextLargeView: {
        display: "inline",
        '@media (max-width: 1140px)': {
            display: "none"
        }
    },
    conversationStyleTextSmallView: {
        display: "none",
        '@media (max-width: 1140px)': {
            display: "inline"
        }
    }
});

interface TempProps {
    temperatureValue: number;
    setTemperatureValue: React.Dispatch<React.SetStateAction<number>>;
}

export const TempControl = ({ temperatureValue, setTemperatureValue }: TempProps) => {
    const styles = useStyles();
    //const [temperatureValue, setTemperatureValue] = React.useState(Temp[Temp.Balanced]);
  
    return (
        <div style={{ margin: "5px" }}>
            <Popover withArrow={true}>
                <PopoverTrigger disableButtonEnhancement>
                    <Button>
                        <span className={styles.conversationStyleTextLargeView}>{`Conversation style: ${Temp[temperatureValue]}`}</span>
                        <span className={styles.conversationStyleTextSmallView}>{Temp[temperatureValue]}</span>
                    </Button>
                </PopoverTrigger>

                <PopoverSurface>
                    <div>
                        <h4 className={styles.contentHeader}>Choose a conversation style:</h4>
                        <RadioGroup
                            value={temperatureValue.toString()}
                            onChange={(_, data) => {
                                // data.value is a string of the Temp enum value (ex. "0.5")
                                // Need to call setTemperatureValue(number)
                                setTemperatureValue(Number(data.value))
                            }}
                        >
                            <Radio value={Temp.Adventurous.toString()}
                                label={
                                    <>
                                        {Temp[Temp.Adventurous]}
                                        <br />
                                        <Text size={200}>
                                            Amusing and unpredictable
                                        </Text>
                                    </>
                                } />
                            <Radio value={Temp.Creative.toString()}
                                label={
                                    <>
                                        {Temp[Temp.Creative]}
                                        <br />
                                        <Text size={200}>
                                            Original and imaginative
                                        </Text>
                                        </>
                                } />
                            <Radio value={Temp.Balanced.toString()}
                                label={
                                    <>
                                        {Temp[Temp.Balanced]}
                                        <br />
                                        <Text size={200}>
                                            Informative and friendly
                                        </Text>
                                        </>
                                } />
                            <Radio value={Temp.Thoughtful.toString()}
                                label={
                                    <>
                                        {Temp[Temp.Thoughtful]}
                                        <br />
                                        <Text size={200}>
                                            Accurate and meaningful
                                        </Text>
                                    </>
                                } />
                            <Radio value={Temp.Precise.toString()}
                                label={
                                    <>
                                        {Temp[Temp.Precise]}
                                        <br />
                                        <Text size={200}>
                                            Concise and straightforward
                                        </Text>
                                    </>
                                } />
                        </RadioGroup>
                    </div>
                </PopoverSurface>
            </Popover>
        </div>
    );
};
