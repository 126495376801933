import { Button } from '@fluentui/react-components';
import { InfoRegular } from '@fluentui/react-icons';

export const QRGButton: React.FC = () => {
    return (
        <Button
            data-testid="qrgButton"
            style={{ color: 'white' }}
            appearance="transparent"
            icon={<InfoRegular color="white" />}
            title="Quick Reference Guide"
            onClick={() => {
                window.open(process.env.REACT_APP_COPILOT_QRG_LINK, '_blank');
            }}
        />
    );
};
