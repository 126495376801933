import { Avatar, AvatarSize, Persona } from '@fluentui/react-components';
import { FC, useEffect, useRef } from 'react';
import { getIconByName } from '../../libs/utils/PersonaIconComponentUtils';

interface IFluentIconViewerProps {
    iconName?: string;
    iconSize?: number;
    className?: string;
    iconBackgroundColor?: string;
    [key: string]: any;
}

export const FluentIconViewer: FC<IFluentIconViewerProps> = ({
    iconName,
    iconSize,
    className,
    iconBackgroundColor,
    ...rest
}) => {
    const avatarRef = useRef<HTMLSpanElement>(null);
    useEffect(() => {
        if (avatarRef.current) {
            const spanElement = avatarRef.current.querySelector('span');
            if (spanElement) {
                if (iconBackgroundColor) {
                    spanElement.style.backgroundColor = iconBackgroundColor;
                } else {
                    spanElement.style.backgroundColor = '';
                }
            }
        }
    }, [iconBackgroundColor]);
    
    return (
        <Persona
            className={className}
            avatar={{
                icon:
                    <Avatar
                        icon={getIconByName(iconName ? iconName : "PersonRegular", iconSize, iconBackgroundColor)}
                        size={iconSize as AvatarSize}
                        ref={avatarRef}
                        {...rest}
                    />,
            }}
        />
        
    );
};
