export const getIconColorByBackgroundColor = (backgroundColor: string | undefined): string => {
    if (!backgroundColor)
        return '#000000';

    // Derived from WCAG 2.0 Standards (https://www.w3.org/TR/WCAG20/).
    // Intent is to maximize contrast ratio between icon color (white or black) and icon background color (any hex).
    const { r, g, b } = hexToRgb(backgroundColor);
    const rFractional = r / 255;
    const gFractional = g / 255;
    const bFractional = b / 255;

    // Calculate relative luminance of the background color
    // L = 0.2126 * R + 0.7152 * G + 0.0722 * B where R, G and B are defined as:
    //         if RsRGB <= 0.03928 then R = RsRGB/12.92 else R = ((RsRGB+0.055)/1.055)^2.4
    //         if GsRGB <= 0.03928 then G = GsRGB/12.92 else G = ((GsRGB+0.055)/1.055)^2.4
    //         if BsRGB <= 0.03928 then B = BsRGB/12.92 else B = ((BsRGB+0.055)/1.055)^2.4
    //     and RsRGB, GsRGB, and BsRGB are defined as:
    //         RsRGB = R8bit/255
    //         GsRGB = G8bit/255
    //         BsRGB = B8bit/255
    const rLuminance = rFractional <= 0.03928 ? rFractional / 12.92 : Math.pow((rFractional + 0.055) / 1.055, 2.4);
    const gLuminance = gFractional <= 0.03928 ? gFractional / 12.92 : Math.pow((gFractional + 0.055) / 1.055, 2.4);
    const bLuminance = bFractional <= 0.03928 ? bFractional / 12.92 : Math.pow((bFractional + 0.055) / 1.055, 2.4);
    const relativeLuminance = 0.2126 * rLuminance + 0.7152 * gLuminance + 0.0722 * bLuminance;

    // Relative luminance of white and black
    const relativeLuminanceWhite = 1.0;
    const relativeLuminanceBlack = 0.0;

    // Calculate contrast ratio with white and black
    // Definition(L1 + 0.05) / (L2 + 0.05), where:
    //     L1 is the relative luminance of the lighter of the colors, and
    //     L2 is the relative luminance of the darker of the colors.
    const contrastRatioWithWhite = (relativeLuminanceWhite + 0.05) / (relativeLuminance + 0.05);
    const contrastRatioWithBlack = (relativeLuminance + 0.05) / (relativeLuminanceBlack + 0.05);
    return contrastRatioWithWhite > contrastRatioWithBlack ? '#FFFFFF' : '#000000';
};

// Convert six character hex color (with or without #) to RGB
const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
    const hexValue = hex.replace('#', '');
    const r = parseInt(hexValue.substring(0, 2), 16);
    const g = parseInt(hexValue.substring(2, 4), 16);
    const b = parseInt(hexValue.substring(4, 6), 16);
    return { r, g, b };
}

export const defaultPersonaBackgroundColors = [
    "#00534e",
    "#007d74",
    "#00a69b",
    "#31fff1",
    "#75fff6",
    "#bafffa",
    "#002b4a",
    "#004070",
    "#005695",
    "#26a3ff",
    "#6fc2ff",
    "#b7e0ff",
];

export const getRandomDefaultPersonaBackgroundColor = (): string => {
    return defaultPersonaBackgroundColors[Math.floor(Math.random() * (defaultPersonaBackgroundColors.length))];
};