import { Button } from '@fluentui/react-button';
import { Body1, Field, Input, Spinner, Switch, Textarea, makeStyles, tokens } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { FormEvent, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { usePersonas } from '../../../../libs/hooks/usePersonas';
import { store } from '../../../../redux/app/store';
import { FluentIconPicker } from '../../../icon-picker/FluentIconPicker';

const useClasses = makeStyles({
    root: {
        width: '600px',
    },
    actions: {
        paddingTop: '10%',
    },
    createButton: {
        marginTop: tokens.spacingVerticalXL,
        marginBottom: tokens.spacingVerticalXL,
        marginLeft: tokens.spacingHorizontalXL,
        marginRight: tokens.spacingHorizontalXL,
        width: 'fit-content',
    },
    textareaInput: {
        height: '100px',
    },
    error: {
        color: '#d13438',
    },
    iconQuestions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1rem',
    },
    content: {
        "& > div:not(:first-child)": {
            marginTop: '.5rem',
        }
    },
});

interface IEditPersonaDialogProps {
    personaId: string;
}

export interface EditPersonaDialogRef {
    openDialog: () => void;
}

export const EditPersonaDialog = forwardRef<EditPersonaDialogRef, IEditPersonaDialogProps>(({ personaId }, ref) => {
    const personaNameRef = useRef<HTMLInputElement>(null);
    const classes = useClasses();
    const personas = usePersonas();

    const [personaName, setPersonaName] = useState('');
    const [personaInstructions, setPersonaInstructions] = useState('');
    const [personaIcon, setPersonaIcon] = useState<string | undefined>('');
    const [chooseIcon, setChooseIcon] = useState(false);
    const [personaIconBackgroundColor, setPersonaIconBackgroundColor] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const [personaPlugins] = useState(store.getState().personas.personaPlugins);
    const [pluginSwitches, setPluginSwitches] = useState(() => {
        const pluginSwitchesInitial: Record<string, boolean> = {};
        return pluginSwitchesInitial;
    });

    const handleSwitchChange = (plugin: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setPluginSwitches({
            ...pluginSwitches,
            [plugin]: event.target.checked
        });
    };

    const iconChangePerPicker = (iconName: string, iconBackgroundColor: string) => {
        setPersonaIcon(iconName);
        setPersonaIconBackgroundColor(iconBackgroundColor);
    };

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (submitting) {
            return;
        }
        setSubmitting(true);
        const personaPluginsSelected = Object.keys(pluginSwitches).filter((plugin) => pluginSwitches[plugin]);
        void personas.editPersona(personaId, personaName, personaInstructions, personaIcon,
                chooseIcon, personaPluginsSelected, personaIconBackgroundColor)
            .then(() => {
                setOpen(false);
                setErrorMessage('');
            })
            .catch(() => {
                setErrorMessage('Failed to create persona. Please try again later.');
            })
            .finally(() => { 
                setSubmitting(false);
            });
    };

    // Expose setOpen method to parent components
    useImperativeHandle(ref, () => ({
        openDialog: () => {
            // Load persona data from redux store
            setPersonaName(store.getState().personas.personas[personaId].name);
            setPersonaInstructions(store.getState().personas.personas[personaId].instructions);
            setPersonaIcon(store.getState().personas.personas[personaId].iconName);
            setPersonaIconBackgroundColor(store.getState().personas.personas[personaId].iconBackgroundColor);

            // Set plugin switches
            setPluginSwitches(() => {
                const pluginSwitchesInitial: Record<string, boolean> = {};
                const currentlyEnabledPlugins = store.getState().personas.personas[personaId].pluginNames;
                for (const plugin of personaPlugins) {
                    pluginSwitchesInitial[plugin.pluginName] = currentlyEnabledPlugins.includes(plugin.pluginName);
                }
                return pluginSwitchesInitial;
            });

            setOpen(true);
        }
    }));

    return (
        <Dialog
            modalType="alert"
            open={open}
            onOpenChange={(_event, data) => {
                setOpen(data.open);
            }}
        >
            <DialogSurface className={classes.root}>
                <form onSubmit={handleSubmit}>
                    <DialogBody>
                        <DialogTitle>Edit Persona:</DialogTitle>
                        <DialogContent className={classes.content}>
                            {errorMessage && <Body1 className={classes.error}>{errorMessage}</Body1>}
                            <Field label="Persona Name (Reference Only)">
                                <Input
                                    required
                                    type="text"
                                    value={personaName}
                                    onChange={(_e, input) => {
                                        setPersonaName(input.value);
                                    }}
                                    ref={personaNameRef}
                                />
                            </Field>
                            <Field label="Instructions">
                                <Textarea
                                    required
                                    textarea={{ className: classes.textareaInput }}
                                    resize='vertical'
                                    value={personaInstructions}
                                    onChange={(_e, input) => {
                                        setPersonaInstructions(input.value);
                                    }}
                                    placeholder='Customize the way the AI responds to you...'
                                />
                            </Field>
                            <Field label="Icon">
                                <div className={classes.iconQuestions}>
                                    <FluentIconPicker
                                        currentIconName={personaIcon}
                                        currentIconBackgroundColor={personaIconBackgroundColor}
                                        iconOnChange={iconChangePerPicker}
                                        focusOnClose={() => {
                                            // Focus on the input field after the icon picker closes
                                            // To fix default focus on background bug
                                            personaNameRef.current?.focus();
                                        }}
                                    />
                                    <Switch
                                        label="Automatically pick an icon if one isn't chosen"
                                        checked={chooseIcon}
                                        onChange={(_, data) => { 
                                            setChooseIcon(data.checked);
                                        }}
                                    />
                                </div>
                            </Field>
                            {process.env.REACT_APP_PLUGIN_SELECTION_DISABLED !== 'true' && (
                                <Field label="Plugins">
                                    {personaPlugins.map((plugin) => (
                                        <Switch
                                            key={plugin.pluginName}
                                            label={`${plugin.pluginDisplayName} - ${plugin.pluginDescription}`}
                                            checked={pluginSwitches[plugin.pluginName]}
                                            onChange={handleSwitchChange(plugin.pluginName)}
                                        />
                                    ))}
                                </Field>
                            )}
                        </DialogContent>
                        <DialogActions className={classes.actions}>
                            <DialogTrigger action="close" disableButtonEnhancement>
                                <Button appearance="secondary">Cancel</Button>
                            </DialogTrigger>
                            <Button
                                appearance="primary"
                                type="submit"
                                icon={submitting ? <Spinner size="tiny" /> : undefined}
                                disabledFocusable={submitting}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog>
    );
});

EditPersonaDialog.displayName = 'EditPersonaDialog';