import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { PersonaExploreButton } from './PersonaExploreButton';
import { PersonaListItem } from './PersonaListItem';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap(tokens.spacingVerticalXXS),
        // paddingBottom: tokens.spacingVerticalXS,
    },
});

interface IPersonaListSectionProps {
    isConversationPaneFill: boolean;
    closeConversationPanel: () => void;
}

export const PersonaListSection: React.FC<IPersonaListSectionProps> = ({
    isConversationPaneFill,
    closeConversationPanel,
}) => {
    const classes = useClasses();
    const pinnedPersonas: string[] = useAppSelector((state: RootState) => state.personas.pinnedPersonas);

    return (
        <>
            {pinnedPersonas.length > 0 &&
                <div className={classes.root}>
                    {pinnedPersonas.map((id) => {
                        return (
                            <PersonaListItem
                                id={id}
                                key={id}
                                isConversationPaneFill={isConversationPaneFill}
                                closeConversationPanel={closeConversationPanel}
                            />
                        );
                    })}
                </div>
            }
            <PersonaExploreButton
                isConversationPaneFill={isConversationPaneFill}
                closeConversationPanel={closeConversationPanel}
            />
        </>
    );
};
