import { Button } from '@fluentui/react-button';
import { DialogOpenChangeData, makeStyles } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { usePersonas } from '../../../../libs/hooks/usePersonas';
import { store } from '../../../../redux/app/store';

const useClasses = makeStyles({
    root: {
        width: '450px',
    },
    actions: {
        paddingTop: '10%',
    },
});

interface IConvertToGlobalPersonaDialogProps {
    personaId: string;
}

export interface ConvertToGlobalPersonaDialogRef {
    openDialog: () => void;
}

export const ConvertToGlobalPersonaDialog =
    forwardRef<ConvertToGlobalPersonaDialogRef, IConvertToGlobalPersonaDialogProps>(({ personaId }, ref) => {
    const classes = useClasses();
    const personas = usePersonas();

    const [personaName, setPersonaName] = useState('');
    const [open, setOpen] = useState(false);

    const onConvertToGlobal = () => {
        void personas.convertToGlobal(personaId);
    };

    // Expose setOpen method to parent components
    useImperativeHandle(ref, () => ({
        openDialog: () => {
            // Load persona data from redux store
            setPersonaName(store.getState().personas.personas[personaId].name);
            
            setOpen(true);
        }
    }));

    return (
        <Dialog
            modalType="alert"
            open={open}
            onOpenChange={(_ev: any, data: DialogOpenChangeData) => {
                setOpen(data.open);
            }}
        >
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle>Are you sure you want to convert persona &quot;{personaName}&quot; to a Global persona?</DialogTitle>
                    <DialogContent>
                        This action cannot be undone.
                        The persona must be deleted and recreated since other users may create chat sessions with this persona.
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="secondary">Cancel</Button>
                        </DialogTrigger>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="primary" onClick={onConvertToGlobal}>
                                Convert
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
});

ConvertToGlobalPersonaDialog.displayName = 'ConvertToGlobalPersonaDialog';